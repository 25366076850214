body {
  margin: 0;
  font-family: "Myriad Pro", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

/* a::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: -0.5em;
  top: -0.25em;
  opacity: 0;
  transition: all 0.25s ease;

  background-color: yellow;
  width: calc(100% + 1em);
  height: 2.5em;

  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  border-top: 0 solid yellow; 
}

 a:hover {
  text-decoration: none;
} 

a:hover::before {
  opacity: 1;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 100px solid yellow; 
} 

a:active::before {
  opacity: 1;
  height: 4.5em;
  border-top: 150px solid yellow; 
}*/

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-image: url("./CJ_marble.jpg");
  background-size: cover;
  margin: 0;
}

.wrapper.dark {
  background-color: black;
  background-image: none;
  color: white;
}

.top-container {
  width: 100%;
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.middle-container {
  flex-grow: 1;
  width: 100%;
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottom-container {
  width: 100%;
}

.bear-container {
  margin-bottom: 1em;
}

.the-bears {
  position: absolute;
  width: 100vw;
  height: 7em;
  background-size: contain;
  background-repeat: repeat;
  background-image: url("./ScreambearEmpty.gif");
  z-index: 1;
}

.secret-phase-form {
  padding: 1em 0;
}

.one-bear {
  background-image: url("./ScreambearEmpty.gif");
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: -1.5em;
  width: 3em;
  height: 3em;
}

.crypto-jerry-hat {
  background-image: url("./IMG_9900.gif");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.header a {
  color: blue;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5em;
}

.buy-with-crypto {
  width: 10em;
  height: 10em;
  margin-top: 2em;
  margin-bottom: 1em;
  max-width: 330px;
}

.input {
  outline: 0;
  border: 0;
  border-bottom: solid 1px black;
  font-size: 1em;
  padding: 4px;
  background-color: transparent;
}

.dark .input {
  border-color: white;
  color: white;
}

.input::placeholder {
  font-style: italic;
  color: rgb(129, 129, 129);
}

.secret-input {
  text-align: center;
}

.engraved {
  color: #e6e6e6;
  font-size: 3em;
  text-shadow: 0px 1px 0px rgb(255 255 255 / 30%), 0px -1px 0px rgb(0 0 0 / 70%);
}

button {
  border: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.25s ease;
  position: relative;
}

.dark button {
  color: white;
}

button:active::before {
  height: 2em;
}

button::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 12px;
  bottom: 0;
  opacity: 0;
  width: calc(100% - 12px);
  height: 0.5em;
  background-color: yellow;
  transition: all 0.25s ease;
}

.dark button::before {
  background-color: rgb(24, 24, 24);
}

button:hover::before {
  opacity: 1;
}

button:disabled:hover::before {
  opacity: 0.1;
}

@keyframes shake {
  0% {
    transform: translate(0) rotate(0) scale(1);
  }
  20% {
    transform: translate(1em) rotate(1deg) scale(1.1);
  }
  40% {
    transform: translate(-1em) rotate(1deg) scale(1.1);
  }
  60% {
    transform: translate(1em) rotate(-1deg) scale(1.1);
  }
  80% {
    transform: translate(-1em) rotate(-1deg) scale(1.1);
  }
  100% {
    transform: translate(0) rotate(0) scale(1);
  }
}

@keyframes shakeSimple {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(1em);
  }
  40% {
    transform: translate(-1em);
  }
  60% {
    transform: translate(1em);
  }
  80% {
    transform: translate(-1em);
  }
  100% {
    transform: translate(0);
  }
}

.has-guessed-wrong,
.dark .has-guessed-wrong {
  animation: shakeSimple 0.5s infinite;
  color: red;
  transition: all 0.25s ease;
}

.has-guessed-wrong input,
.dark .has-guessed-wrong input {
  /* animation: shake 0.3s infinite; */
  color: red;
  border-color: red;
  transition: all 0.25s ease;
}

.doo-dah-man {
  width: 50%;
}

@media screen and (min-width: 700px) {
  .buy-with-crypto {
    width: 14em;
    height: 14em;
  }
}

@media screen and (max-height: 659px) {
  .buy-with-crypto {
    height: 8em;
    width: 8em;
  }
}

@media screen and (max-height: 459px) {
  .buy-with-crypto {
    height: 5em;
    width: 5em;
  }

  .wrapper {
    height: auto;
    overflow: scroll;
  }
}
